function initMap(address) {

    jQuery(function($) {

        var mainContainer = $('#property-press');
        var currentStep = 1;
        var formId_1 = mainContainer.attr('data-form-1');
        var formId_2 = mainContainer.attr('data-form-2');
        var formField_1 = mainContainer.attr('data-field-1');
        var formField_2 = mainContainer.attr('data-field-2');
        var geocoder = new google.maps.Geocoder();
        var getZillow = mainContainer.attr('data-zestimate');

        geocoder.geocode( { 'address': address}, function(results, status) {
            console.log('geocoder: ');
            console.log(results);
            
            if ( status === 'OK' ) {
                var latitude = results[0].geometry.location.lat();
                var longitude = results[0].geometry.location.lng();
                var myLatLng = {lat: latitude, lng: longitude};

                var map = new google.maps.Map(document.getElementById('property-press-map'), {
                    zoom: 17,
                    scrollwheel: false,
                    draggable: false,
                    center: myLatLng
                });

                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                });

                google.maps.event.addDomListener(window, 'resize', function() {
                    map.setCenter(myLatLng);
                });

                // Street view
                /*
                <?php if ($streetview) : ?>
                    var panorama = new google.maps.StreetViewPanorama(
                        document.getElementById('BrandcoLandingPages_Map'), {
                            position: myLatLng,
                        }
                    );
                    map.setStreetView(panorama);

                    var sv = new google.maps.StreetViewService();

                    sv.getPanorama({
                        location: myLatLng,
                        radius: 50
                    }, function(data, status) {
                        if (status === google.maps.StreetViewStatus.OK) {

                            var marker_pano = new google.maps.Marker({
                                position: myLatLng,
                                map: panorama
                            });

                            var heading = google.maps.geometry.spherical.computeHeading(data.location.latLng, marker_pano.getPosition());

                            panorama.setPov({
                                heading: heading,
                                pitch: 0
                            });
                        }
                    });
                <?php endif; ?>
                */

                /*
                // Zillow API
                <?php if ($show_zillow) : ?>

                    // Save address data
                    var addressData = results[0];

                    // Vars
                    var streetNumber, streetName, cityName, stateName, zipCode, zillowAddress, zillowLocation;

                    // If address is a street address 
                    if ( addressData.types[0] === "street_address" ) {

                        // Loop through address data and set variables for each
                        // part of the address that is needed
                        for (var i = 0; i < addressData.address_components.length; i++) {

                            // Get street number
                            if ( addressData.address_components[i].types[0] === "street_number" ) {
                                streetNumber = addressData.address_components[i].long_name;
                            }

                            // Get street name
                            if ( addressData.address_components[i].types[0] === "route" ) {
                                streetName = addressData.address_components[i].long_name;
                            }

                            // Get city
                            if ( addressData.address_components[i].types[0] === "locality" ) {
                                cityName = addressData.address_components[i].long_name;
                            }

                            // Get state
                            if ( addressData.address_components[i].types[0] === "administrative_area_level_1" ) {
                                stateName = addressData.address_components[i].long_name;
                            }

                            // Get zip
                            if ( addressData.address_components[i].types[0] === "postal_code" ) {
                                zipCode = addressData.address_components[i].long_name;
                            }
                        }

                        // Prepare the final variables for Zillow
                        zillowAddress = streetNumber + ' ' + streetName;
                        zillowLocation = cityName + ' ' + stateName + ' ' + zipCode;

                        // Send request to Zillow

                        $.ajax({
                            url: '<?php echo plugin_dir_url( __FILE__ ); ?>get-zillow-data.php',
                            type: 'POST',
                            data: {
                                'zws-id': 'X1-ZWz19j8np7wjd7_6ttia',
                                'address': zillowAddress,
                                'citystatezip': zillowLocation
                            },
                            success: function(data) {
                                var jsonData = JSON.parse(data);
                                console.log(jsonData);

                                if ( jsonData.message.code === "0" ) {
                                    var allData = jsonData.response.results.result;
                                    var zestimate = allData.zestimate.amount;
                                    var inDollars = '$' + (zestimate + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                    $('.gform_heading').append('<div class="gform_description">Zillow Zestimate: ' +  inDollars + '</div>');

                                } else {
                                    console.log('Error');
                                }
                            }
                        });

                    } else {
                        console.log('Address is not a street address');
                    }
                <?php endif; ?>
                */

                // If get Zillow estimate
                if ( getZillow === 'true' ) {

                    console.log('Getting Zillow Zestimate...');

                    // Save address data
                    var addressData = results[0];

                    console.log('addressData:'); console.log(addressData);

                    // Vars
                    var streetNumber, streetName, cityName, stateName, zipCode, zillowAddress, zillowLocation;

                    // If address is a street address 
                    if ( addressData.types[0] === "street_address" || addressData.types[0] === "premise" ) {

                        // Loop through address data and set variables for each
                        // part of the address that is needed
                        for (var i = 0; i < addressData.address_components.length; i++) {

                            // Get street number
                            if ( addressData.address_components[i].types[0] === "street_number" ) {
                                streetNumber = addressData.address_components[i].long_name;
                            }

                            // Get street name
                            if ( addressData.address_components[i].types[0] === "route" ) {
                                streetName = addressData.address_components[i].long_name;
                            }

                            // Get city
                            if ( addressData.address_components[i].types[0] === "locality" ) {
                                cityName = addressData.address_components[i].long_name;
                            }

                            // Get state
                            if ( addressData.address_components[i].types[0] === "administrative_area_level_1" ) {
                                stateName = addressData.address_components[i].long_name;
                            }

                            // Get zip
                            if ( addressData.address_components[i].types[0] === "postal_code" ) {
                                zipCode = addressData.address_components[i].long_name;
                            }
                        }

                        // Prepare the final variables for Zillow
                        zillowAddress = streetNumber + ' ' + streetName;
                        zillowLocation = cityName + ' ' + stateName + ' ' + zipCode;

                        console.log('address: ' + zillowAddress);
                        console.log('citystatezip: ' + zillowLocation);

                        // Send request to Zillow
                        $.ajax({
                            url: pp_ajax_url,
                            type: 'POST',
                            data: {
                                'action': 'get_zillow_data',
                                'zws-id': 'X1-ZWz19j8np7wjd7_6ttia',
                                'address': zillowAddress,
                                'citystatezip': zillowLocation
                            },
                            success: function(data) {
                                var jsonData = JSON.parse(data.data.zillow_data);
                                console.log({data: data, jsonData: jsonData});

                                if ( jsonData.message.code === "0" ) {
                                    var allData = jsonData.response.results.result;
                                    var zestimate = allData.zestimate.amount;
                                    var inDollars = '$' + (zestimate + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                    $('.property-press-zillow').append('Zillow estimates your home is worth ' +  inDollars + '.');

                                } else {
                                    console.log('Error');
                                }
                            }
                        });

                    } else {
                        console.log('Address is not a street address');
                    }
                }

            } else {

                setTimeout(function() {
                    console.log('errrrrrorrr');
                }, 500);

            };
        }); 
    
    });

}

function initAutocomplete() {
	var mainContainer = document.getElementById('property-press');
	var formId_1 = mainContainer.getAttribute('data-form-1');
	var formField_1 = mainContainer.getAttribute('data-field-1');
	var addressField = document.getElementById('input_' + formId_1 + '_' + formField_1);
	var autocomplete = new google.maps.places.Autocomplete(addressField);
}

jQuery(function($) {

	// Get all data
	var mainContainer = $('#property-press');
	var currentStep = 1;
	var formId_1 = mainContainer.attr('data-form-1');
	var formId_2 = mainContainer.attr('data-form-2');
	var formField_1 = mainContainer.attr('data-field-1');
	var formField_2 = mainContainer.attr('data-field-2');
	var addressField = $('#input_' + formId_1 + '_' + formField_1);
	var addressFieldHidden = $('#input_' + formId_2 + '_' + formField_2);

	// Field active or complete
	$(document).ready(function(){
		var gfields = $('li.gfield .ginput_container input, li.gfield .ginput_container textarea');
		gfields.focus(function(){
			$(this).parent().parent().addClass('field-active');
		});
		gfields.blur(function(){
			$(this).parent().parent().removeClass('field-active');
			if( $(this).val().length !== 0 ) {
				$(this).parent().parent().addClass('field-complete');
			} else {
				$(this).parent().parent().removeClass('field-complete');
			}
		});
	});

	function autofillAddress() {
		var addressValue = addressField.val();
		addressFieldHidden.val(addressValue);
	}

	function fadeOutStep1() {

		setTimeout(function() {

			$('#property-press-step-1').css({
				'display': 'none'
			});
		}, 600);
	}

    $('#gform_submit_button_' + formId_1).click(function() {
        fadeStepOut(1);
        mainContainer.attr('data-loading', true);
    });

    // $('#gform_submit_button_' + formId_2).click(function() {
    //     fadeStepOut(3);
    //     mainContainer.attr('data-loading', true);
    // });

	function fadeStepOut(id) {

        $('#property-press-step-' + id).css({
            'opacity': '0'
        });

		setTimeout(function() {

			$('#property-press-step-' + id).css({
				'display': 'none'
			});
		}, 600);
	}

	function fadeStepIn(id) {

		setTimeout(function() {

			$('#property-press-step-' + id).css({
				'display': 'block'
			});

			setTimeout(function() {

				$('#property-press-step-' + id).css({
					'opacity': '1'
				});
			}, 100);
		}, 600);
	}

	// After form submit
	$(document).bind('gform_confirmation_loaded', function(event, formId) {
        console.log(event);

		if (parseInt(formId) === parseInt(formId_1)) {
			console.log('Form 1 Submitted');
            mainContainer.attr('data-loading', false);
			mainContainer.attr('data-current-step', 2);
			autofillAddress();
			fadeStepIn(2);
			var addressValue = addressField.val();
			initMap(addressValue);
		}

		else if (parseInt(formId) === parseInt(formId_2)) {
            fadeStepOut(3);
            mainContainer.attr('data-loading', false);
			mainContainer.attr('data-current-step', 4);
			fadeStepIn(4);
		}
	});

	// Remove Google Maps placeholder for address field
	$('input, textarea').attr('placeholder', '');

	function addDisabled(formId) {
		$('#gform_submit_button_' + formId).attr('disabled', 'disabled');
	}

	function checkForRequiredFieldsCompleted(formId) {
		var isComplete = [];
		var complete;
		var requiredFields = $('#gform_' + formId + ' .gfield_contains_required');

		requiredFields.each(function(array, callback) {
			var _ = $(this);

			if ( _.find('input, textarea').val() ) {
				isComplete.push(true);
			} else {
				isComplete.push(false);
			}
		});

		if ( isComplete.indexOf(false) === -1 ) {
			complete = true;
		} else {
			complete = false;
		}

		return complete;
	}

	function removeDisabledAttr(formId) {
		$('#gform_submit_button_' + formId).removeAttr('disabled');
	}

	$(document).ready(function() {
		addDisabled(formId_1);
		addDisabled(formId_2);
	});

    $(document).ready(function() {
        $('.ginput_container_phone input').attr('type', 'tel');
        $('.ginput_container_email input').attr('type', 'email');
    });

	$('input, textarea').keyup(function() {

		if ( checkForRequiredFieldsCompleted(formId_1) === true ) {
			removeDisabledAttr(formId_1);
		} else {
			addDisabled(formId_1);
		}

		if ( checkForRequiredFieldsCompleted(formId_2) === true ) {
			removeDisabledAttr(formId_2);
		} else {
			addDisabled(formId_2);
		}
	});

	$('[data-confirm-address]').click(function(event) {
		event.preventDefault();
		mainContainer.attr('data-current-step', 3);
		fadeStepOut(2);
		fadeStepIn(3);
		setTimeout(function() {
			$('.success-icon').addClass('success-icon-go');
		}, 300);
	});

	function makeRoomForFooter() {
		var footerHeight = $('#property-press-footer').outerHeight();

		$('#property-press-main').css({'padding-bottom' : footerHeight});
	}
	$(document).ready(function() {
		makeRoomForFooter();
	})
});



